<template>
  <card class="card shadow-sm" body-classes="standard-card-body">
    <template slot="header">
      <h4 class="card-title d-inline text-capitalize text-info">{{ title }}</h4>
    </template>
    <ul class="list-group list-group-flush">
      <li class="standard-label"
           v-for="(user, index) in users"
           v-bind:key="`${title}-${index}`">
        {{ getUserName(user) }}
      </li>
    </ul>
  </card>
</template>

<script>
export default {
  name: "UserList",
  props: {
    title: {
      type: String,
      default: () => 'user'
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getUserName(user) {
      if (!user) {
        return '';
      }

      const lastname = user?.lastname || '';
      const firstname = user?.firstname || '';

      return lastname || firstname ? lastname + ' ' + firstname : (user?.username || 'N.D.');
    },
  }
}
</script>

<style scoped>

</style>
