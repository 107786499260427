<template>
  <ValidationObserver ref="reportFirstStep">
    <div class="container-fluid">
      <div class="row justify-content-center mb-3">
        <div class="col-auto text-center">
          <p>{{ $t('reports.wizard.first_step_title') }}</p>
          <p>{{ $t('reports.wizard.first_step_subtitle') }}</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-6">
          <ValidationProvider name="date" rules="required" v-slot="{ passed, failed, errors }">
            <base-input :label="$t('reports.period')"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            >
              <el-date-picker
                v-model="datePickerValue"
                type="daterange"
                align="right"
                unlink-panels
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                range-separator="-"
                :start-placeholder="$t('fields.date_start')"
                :end-placeholder="$t('fields.date_end')"
                :picker-options="pickerOptions"
              />
            </base-input>
          </ValidationProvider>
        </div>
      </div>

      <div class="row justify-content-center">

        <list-selector-component
          show-select-all
          :list="typeOptions"
          :number-items-per-page="typeOptions.length"
          ref="reportOptions"
        >
          <template v-slot:default="slotProps">
            {{ $t('reports.types.' + slotProps.item.id) }}<br>
            {{ $t('reports.descriptions.' + slotProps.item.id) }}
          </template>
        </list-selector-component>

      </div>

    </div>
  </ValidationObserver>
</template>

<script>

import {DatePicker, Select, Option} from "element-ui";
import reportConst from "@/pages/Reports/resources/reportConst";
import ListSelectorComponent from "@/components/ListSelectorComponent";

export default {
  name: "ReportWizardFirstStep",
  components: {ListSelectorComponent, [DatePicker.name]: DatePicker, [Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      momentFormat: 'yyyy-MM-DD',
      shortcutsDatePicker: ['current-week', 'last-week', 'current-month', 'last-month'],
      datePickerValue: [],
      reportTypes: [],
      reportConst: reportConst,
      typeOptions: [],
    }
  },
  computed: {
    pickerOptions: function () {
      const shortcuts = [];
      this.$_.each(this.shortcutsDatePicker, (item) => {
        shortcuts.push({
          text: this.$t('chart.' + item),
          onClick: function (picker) {
            picker.$emit('pick', this.getInterval(item));
          }.bind(this)
        })
      });

      return {
        shortcuts: shortcuts,
        firstDayOfWeek: 1
      };
    },
  },
  mounted() {
    this.typeOptions = [];
    this.$_.keys(this.reportConst).forEach((item) => {
      this.typeOptions.push({id: item, selected: false})
    });
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.reportFirstStep.validate()
          .then((success) => {

            this.reportTypes=[];
            this.typeOptions.forEach((item) => {
              if (item.selected) {
                this.reportTypes.push(item.id);
              }
            });

            if (this.reportTypes.length===0){
              success=false;
              this.$notify({type: 'danger', message: this.$t('notifications.data_error')});
            }

            if (success) {
              resolve({
                date_from: this.datePickerValue[0],
                date_to: this.datePickerValue[1],
                reports: this.reportTypes
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
    ,

    getInterval: function (item) {
      switch (item) {
        case 'current-week':
          return [
            this.$moment().startOf('week').format(this.momentFormat),
            this.$moment().endOf('week').format(this.momentFormat)
          ];
        case 'last-week':
          return [
            this.$moment().subtract(1, 'weeks').startOf('week').format(this.momentFormat),
            this.$moment().subtract(1, 'weeks').endOf('week').format(this.momentFormat)
          ];
        case 'current-month':
          return [
            this.$moment().startOf('month').format(this.momentFormat),
            this.$moment().endOf('month').format(this.momentFormat)
          ];
        case 'last-month':
          return [
            this.$moment().subtract(1, 'month').startOf('month').format(this.momentFormat),
            this.$moment().subtract(1, 'month').endOf('month').format(this.momentFormat)
          ];
        default:
          return [];
      }
    }
  }
}
</script>

<style scoped>

</style>
