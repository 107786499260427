<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-12 text-center">
        <p>{{ $t('reports.wizard.third_step_info') }}</p>
      </div>
    </div>
    <div class="row text-body d-flex justify-content-around">
      <div class="col-12 col-md-8">
        <card class="shadow-sm" body-classes="standard-card-body">
          <template slot="header">
            <h4 class="card-title d-inline text-capitalize text-info">
              {{ $t('reports.wizard.details') }}
            </h4>
          </template>
          <div class="list-group-flush">
            <list-group-item-component
              :label="$t('reports.period')"
              :value="`${$options.filters.date(this.date_from)} - ${$options.filters.date(this.date_to)}`"/>

            <list-group-item-component
              :label="$t('reports.list')"
            >
              <template slot="value" >
                <div class="text-right">
                  <div  v-for="(report, index) in reports" v-bind:key="index">
                    <label-theme-component>{{ $t('reports.types.' + report) }}</label-theme-component>
                  </div>
                </div>

              </template>
            </list-group-item-component>

          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import UserList from "../components/UserList";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "ReportWizardThirdStep",
  components: {LabelThemeComponent, ListGroupItemComponent, UserList},
  props: {
    reports: {
      type: Array,
      default: () => []
    },
    date_from: {
      type: String,
      default: () => []
    },
    date_to: {
      type: String,
      default: () => []
    }
  },
  computed: {

  },
}
</script>

<style scoped>

</style>
