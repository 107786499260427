<template>
  <div class="row">
    <div class="col-md-12">

      <simple-wizard
        @tab-change="tabChange"
        @onCompleted="completeWizard"
        :finishButtonText="$t('common.finish')"
        :nextButtonText="$t('common.next')"
        :prevButtonText="$t('common.prev')"
      >

        <template slot="header">
          <h3 class="card-title">{{ $t('reports.wizard.title') }}</h3>
          <h3 class="description">{{ $t('reports.wizard.subtitle') }}</h3>
        </template>

        <wizard-tab :before-change="() => validateStep(1)">
          <template slot="label">
            <octo-icon icon="annual-calendar-page"></octo-icon>
            <p>{{ $t('reports.wizard.details') }}</p>
          </template>
          <report-wizard-first-step ref="reportWizardFirstStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(3)">
          <template slot="label">
            <octo-icon icon="checked"></octo-icon>
            <p>{{ $t('reports.wizard.summary') }}</p>
          </template>
          <report-wizard-third-step ref="reportWizardThirdStep"
                                    :date_to="date_to"
                                    :date_from="date_from"
                                    :reports="reports"
          />
        </wizard-tab>

      </simple-wizard>
    </div>
  </div>
</template>

<script>

import SimpleWizard from "@/components/Wizard/Wizard";
import WizardTab from "@/components/Wizard/WizardTab";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import ReportWizardFirstStep from "@/pages/Reports/report-wizard/ReportWizardFirstStep";
import ReportWizardThirdStep from "@/pages/Reports/report-wizard/ReportWizardThirdStep";
import {MessageBox} from "element-ui";

export default {
  name: "WizardNewReportPage",
  components: {
    ReportWizardThirdStep,
    ReportWizardFirstStep,
    OctoIcon,
    WizardTab, SimpleWizard
  },
  data() {
    return {
      date_to: null,
      date_from: null,
      reports: []
    }
  },
  methods: {
    async validateStep(step) {
      let check = false;

      switch (step) {
        case 1:
          const data = await this.$refs.reportWizardFirstStep.validate();

          if (data) {
            this.date_to = data.date_to;
            this.date_from = data.date_from;
            this.reports = data.reports;
            check = true;
          }
          break;
        case 2:
          check = true;
          break;
        default:
          check = false;
          break;
      }

      if (!check) {
        this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
      }

      return check;
    },

    tabChange(oldTab, newTab) {

    },

    completeWizard: function () {
      this.$fullLoading.show();
      this.$api.post(endpoints.REPORTS_SAVE, {
        date_to: this.date_to,
        date_from: this.date_from,
        reports: this.reports
      })
        .then(() => {
          this.$fullLoading.hide();
          MessageBox({
            title: this.$t('common.good_job'),
            message: this.$t('reports.wizard.back_on_reports_page'),
            confirmButtonText: this.$t('common.ok'),
            type: 'success'
          })
            .finally(() => {
              this.$router.push({name: 'reports.list'});
            });
        })
        .catch(() => {
          this.$fullLoading.hide();
          this.$notify({type: 'danger', message: this.$t('notifications.report_error')});
        })
    }
  }
}
</script>

<style scoped>

</style>
